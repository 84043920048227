.root {
  &:global(.ant-input-number-affix-wrapper) {
    border-color: var(--celeste);
    width: initial;
    border-radius: var(--border-radius);
    align-items: center;
    height: 40px;

    input {
      font-family: var(--font-family-lato);
      font-size: var(--font-size-l);
    }

    :global(.ant-input-number-prefix) {
      border-right: 1px solid var(--celeste);
      color: var(--gull-gray);
      margin-inline-end: 12px;
      padding-inline-end: 12px;
    }
  }
}
