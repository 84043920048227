.checkbox {
  &:global(.ant-checkbox-wrapper) {
    align-items: center;
  }

  :global(.ant-checkbox-inner) {
    border-color: var(--sonic-silver);

    &:after {
      width: 30%;
      height: 66%;
      top: 45%;
      left: 25%;
      border-width: 4px;
      border-color: var(--white);
    }
  }

  :global(.ant-checkbox-indeterminate) {
    :global(.ant-checkbox-inner) {
      &:after {
        top: 50%;
        left: 50%;
        height: 60%;
        width: 60%;
        border-radius: 1px;
        background-color: var(--blue-ribbon);
        border: 0;
      }
    }
  }

  :global(.ant-checkbox) {
    top: 0;

    & + span {
      padding: 0;
      color: var(--text-color-primary);
      font-family: var(--font-family-rubik);
      font-style: normal;
      text-transform: uppercase;
    }
  }

  &.checked {
    :global(.ant-checkbox-inner) {
      background-color: var(--white);
      border-color: var(--sonic-silver);

      &:after {
        width: 30%;
        height: 66%;
        top: 45%;
        left: 25%;
        border-color: var(--blue-ribbon);
      }
    }
  }

  &.small {
    :global(.ant-checkbox-inner) {
      width: 24px;
      height: 24px;

      &:after {
        border-width: 2px;
      }
    }

    :global(.ant-checkbox + span) {
      margin: 0 0 0 16px;
      font-size: var(--font-size-m);
    }
  }

  &.big {
    :global(.ant-checkbox-inner) {
      width: 30px;
      height: 30px;

      &:after {
        border-width: 4px;
      }
    }

    :global(.ant-checkbox + span) {
      margin: 0 0 0 22px;
      font-size: var(--font-size-l);
    }
  }

  &.bigger {
    :global(.ant-checkbox-inner) {
      width: 32px;
      height: 32px;

      &:after {
        border-width: 4px;
      }
    }

    :global(.ant-checkbox + span) {
      margin: 0 0 0 24px;
      font-size: var(--font-size-l);
    }
  }

  &.bold {
    :global(.ant-checkbox) {
      & + span {
        font-weight: var(--font-weight-medium);
      }
    }
  }
}

.checkboxesGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;

  :global(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
    margin: 0;
  }
}

.checkboxesItem {
  flex-grow: 0;
  flex-shrink: 0;
}
