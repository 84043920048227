.root {
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.14px;
}

.text {
  color: var(--text-color-primary);
}

.button {
  border: none;
  background-color: transparent;
  padding: 0;
  color: var(--blue-ribbon);
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: var(--blue-hover);
  }
}

.medium {
  font-size: var(--font-size-xxs);
  line-height: var(--font-size-xxs);
}

.large {
  font-size: var(--font-size-m);
  line-height: var(--font-size-m);
}
