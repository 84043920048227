.selectWrapper {
  label {
    display: block;
    font-size: var(--font-size-m);
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .select {
    width: 100%;
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }
  :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    border-radius: var(--border-radius);
    align-items: center;
    height: 40px;

    & > :global(.ant-select-selection-search) {
      display: inline-flex;
      align-items: center;
    }
  }
  :global(.ant-select-arrow) {
    color: var(--black);
    margin-top: -8px;
  }
}
.fieldLabelInline {
  display: flex;
  align-items: center;
  gap: 20px;
  label {
    font-weight: bold;
    white-space: nowrap;
  }
}
