.root {
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: opacity var(--transition-duration-snappy) ease-in-out;
}

.loading {
  opacity: 0;
}
