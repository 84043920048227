.root {
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: var(--box-shadow-card);
  position: relative;
}

.clickable {
  outline-color: var(--blue-ribbon);
  cursor: pointer;
  transition: all var(--transition-duration-snappy) ease-in;

  &:hover,
  &:focus {
    background-color: var(--concrete);
  }

  &:active {
    filter: brightness(90%);
    box-shadow: var(--box-shadow-card-active);
  }
}

.anchor {
  text-decoration: none;
}
