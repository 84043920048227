@import '../../styles/breakpoints.scss';

.item {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 24px;

  @include smDown {
    gap: 4px;
    flex-wrap: wrap;
  }
}

.text {
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  color: var(--black);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  white-space: normal;
  margin-right: auto;
}

.stat {
  flex: 0 0 auto;
  width: 100px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 8px;
}
