@import '../../styles/breakpoints.scss';

.root {
  position: relative;
}

.header {
  @include mdUp {
    padding: 24px;
  }

  @include smDown {
    padding: 4px 20px;
  }
}

.customizeButton {
  margin-left: auto;

  @include mdUp {
    position: absolute;
    top: 8px;
    right: 20px;
  }

  @include smDown {
    width: 100%;
  }
}

.spinner {
  margin: 24px auto;
}
