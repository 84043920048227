.item {
  display: flex;
  align-items: center;
  gap: 24px;
  height: 36px;
}

.text {
  text-overflow: ellipsis;
  flex: 1 1 auto;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
  color: var(--black);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  white-space: normal;
  margin-right: auto;
}
