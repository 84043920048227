.header {
  margin-bottom: 16px;
}

.slider {
  margin-top: 40px;
}

.list {
  gap: 24px;
}

.item {
  min-height: 100%;
}

.search {
  align-self: flex-end;
  margin-bottom: 24px;
}
