.clearButton {
  font-size: 8px;
  margin: -4px -4px -4px 0;
}
.textSearchField {
  input {
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }
}
