:global(.ant-btn) {
  &.button {
    border-width: 2px;
    color: var(--gull-gray);
    font-family: var(--font-family-rubik);
    font-size: 14px;
    font-weight: var(--font-weight-medium);
    letter-spacing: -0.32px;
    line-height: 18px;

    .icon {
      color: var(--gull-gray);
      margin-right: 4px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &:hover,
    &:focus {
      color: var(--ant-primary-color-hover);

      .icon {
        color: var(--ant-primary-color-hover);
      }
    }

    &:active {
      color: var(--ant-primary-color-active);

      .icon {
        color: var(--ant-primary-color-active);
      }
    }

    &[disabled] {
      &:hover,
      &:focus,
      &:active {
        color: var(--gull-gray);

        .icon {
          color: var(--gull-gray);
        }
      }
    }
  }
}

:global(.ant-btn-round) {
  &.button {
    padding: 4px 20px;
  }
}
