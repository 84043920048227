.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  width: 332px;
}

.image {
  margin: 16px 32px;
  width: calc(100% - 16px * 2);
  flex: 0 0 auto;
  align-self: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 16px 8px 16px;
  flex: 1 0 auto;
  min-height: 154px;
}

.header {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rookieIndicator {
  flex: 0 0 auto;
  margin-right: 4px;
}

.titleText {
  flex: 1 1 auto;
  margin: 0;
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  letter-spacing: -0.2px;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.descriptionText {
  margin: 4px 0 0 0;
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.17px;
  line-height: 12px;
  white-space: normal;
}

.detailsAndPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.extraDetail {
  flex: 0 0 auto;
}

.priceStat {
  flex: 1 1 auto;
  margin-left: auto;
  text-align: end;
}

.stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0 8px 0;
  justify-content: space-between;
  gap: 4px;
  flex-wrap: wrap;
}

.actionsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: auto -8px 0 -8px;
}

.extraActions {
  margin-left: auto;
  flex: 0 0 auto;
}
