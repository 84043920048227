@import '../../sci-ui-components/styles/breakpoints';
.firstRow {
  display: flex;
  gap: 2%;
  width: 100%;
  height: 40%;
  margin-top: 60px;
  margin-bottom: 50px;
  flex-wrap: wrap;

  & > :first-child {
    width: 63%;
    @include mdDown {
      width: 100%;
    }
  }

  & > :nth-child(2) {
    width: 35%;
    @include mdDown {
      width: 100%;
    }
  }
}
