.root {
  padding: 24px;
}

.dense {
  padding: 8px;
}

.active {
  background-color: var(--blue-grey-darker);
}

.disabled {
  opacity: 0.6;
}
