@import '../../sci-ui-components/styles/breakpoints';

.root {
  display: flex;
  min-height: 100vh; /* fall-back */
  width: 100vw;
  padding: 0;

  @include smDown {
    & > :first-child {
      display: none;
    }
  }
}

.sideMenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: var(--black);

  & > :first-child {
    height: 76px;
  }
  & > :not(:first-child) {
    height: 86px;
  }
}

.sideMenuWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100%;
  line-height: 1;
  gap: 6px;
}

.logo {
  padding: 15px 30px;
}

.content {
  position: fixed;
  padding: 0;
  height: 100%;
  left: 100px;
  right: 0;
  top: 0;
  display: grid;
  grid-template-rows: 60px 1fr;
  line-height: 1;

  @include smDown {
    left: 0;
  }
}

.search {
  width: 300px;
  @include mdUp {
    margin-left: 1px;
    width: 500px;
  }
}

.rightSection {
  gap: 1px;
}

.helpMenu {
  margin-right: 10px;
}

.userAccount {
  margin-right: 20px;
}

.page {
  width: 100%;
  line-height: 1;
  overflow: hidden;
}

.rowStack {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 0.25fr);
  gap: 10px;
  margin-top: 20px;
  padding: 0 10px;
  overflow: hidden;
}

.smallRowStack {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(4, 0.25fr);
  padding: 10px 10px 1px 30px;
}
