.root {
  color: var(--black);
  font-family: var(--font-family-rubik);
  font-size: var(--font-sizel);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-medium);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.image {
  width: 68px;
  height: 34px;
  object-fit: contain;
}

.md {
  width: 68px;
  height: 34px;
}

.sm {
  width: 40px;
  height: 20px;
}

.name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
