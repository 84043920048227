.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}

.pageInfo {
  flex: 0 0 auto;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--font-weight-regular);
  color: var(--sonic-silver);
}

.pageSelection {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-medium);
}

.pageButton {
  &:global(.ant-btn) {
    font-size: var(--font-size-m);
    line-height: var(--line-height-m);
    padding: 4px 0;
    width: 40px;
  }
}

.limitSelection {
  flex: 0 0 auto;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--font-weight-regular);
  color: var(--sonic-silver);
  display: flex;
  align-items: center;
}

.limitText {
  margin-right: 4px;
}

.limitButton {
  &:global(.ant-btn) {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-xl);
  }
}

.justifyRight {
  .pageSelection {
    flex: 0 0 auto;
    margin-left: auto;
  }
}
