.clearButton {
  margin: -4px -8px -4px 0;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

.hideClear {
  opacity: 0;
}

.textField {
  border-color: var(--celeste);

  input {
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }

  &:global(.ant-input-affix-wrapper),
  &:global(.ant-input) {
    border-radius: var(--border-radius);
    align-items: center;
    height: 40px;
  }

  :global(.ant-input-prefix) {
    margin-right: 8px;
  }
}

.textAreaWrapper {
  textarea {
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
    border-radius: var(--border-radius);
  }

  :global(.ant-input-affix-wrapper) {
    border-radius: var(--border-radius);
  }
}
