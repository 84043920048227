.root {
  display: flex;
  align-items: center;
  box-shadow: var(--box-shadow-card);
  border-radius: var(--border-radius);
  border: 1px solid var(--sonic-silver);
  height: 40px;
  background-color: var(--white);
  cursor: grab;

  transition: all var(--transition-duration-medium) ease-in;
}

.iconBox {
  flex: 0 0 auto;
  border-right: 1px solid var(--sonic-silver);
  width: 40px;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 20px;
  height: 20px;
  color: var(--sonic-silver);
}

.name {
  flex: 1 1 auto;
  text-align: center;
  margin: 0;
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  font-style: normal;
  text-transform: uppercase;
}
