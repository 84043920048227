.container {
  background-color: transparent;
  transition: all var(--transition-duration-snappy) ease-in;
  border-radius: var(--border-radius);
}

.containerHighlight {
  background-color: var(--ant-primary-1);
  box-shadow: 0px 0px 12px 4px var(--ant-primary-1);
}
