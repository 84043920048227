.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.imageWrapper {
  position: relative;
  cursor: pointer;
}

.buttonWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  display: none;
}

.dropCatcher {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: var(--white);
  opacity: 0.4;
}
