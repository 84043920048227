.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.select {
  flex: 1 1 auto;
  overflow: hidden;
}

.inlineButton {
  flex: 0 0 auto;
}
