@import '../styles/breakpoints.scss';

.root {
  position: relative;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.item {
  scroll-snap-align: start;
}

.button {
  position: absolute;
  top: 0;
  height: 100%;
  width: 44px;
  background-color: var(--white-translucent);
  border: none;
  padding: 0;

  opacity: 0;
  pointer-events: none;

  transition: background-color var(--transition-duration-snappy), box-shadow var(--transition-duration-snappy),
    opacity var(--transition-duration-snappy);

  &:hover {
    background-color: var(--white-opaque);
  }

  &:active {
    box-shadow: 0px 0px 0px #0000001f;
  }

  @include xs {
    display: none;
  }
}

.buttonNext {
  right: 0;
  box-shadow: -4px 0px 4px #0000001f;
}

.buttonPrev {
  left: 0;
  box-shadow: 4px 0px 4px #0000001f;
}

.buttonShow {
  opacity: 1;
  pointer-events: initial;
}

.icon {
  width: 32px;
  height: 32px;
}

.empty {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--concrete);
}

.emptyMessage {
  margin: 0;
  font-size: var(--font-size-l);
  line-height: var(--line-height-l);
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-medium);
}
