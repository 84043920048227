@import '../../styles/breakpoints.scss';

.root {
  color: var(--text-color-primary);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxl);
  font-weight: var(--font-weight-bolder);
  font-style: normal;
  letter-spacing: -0.51px;
  line-height: 32px;
  text-transform: uppercase;

  @include smDown {
    font-size: var(--font-size-xxxl);
    line-height: 32px;
  }
}
