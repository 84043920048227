.root {
  border-radius: 4px;
  background-color: var(--corn);
  padding: 4px 6px;

  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-regular);
  letter-spacing: -0.15px;
  line-height: 12px;
  font-style: normal;
  color: var(--text-color-primary);
  text-align: center;
}
