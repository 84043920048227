@import '../../styles//breakpoints.scss';

.root {
  overflow: hidden;
}

.list {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
}

.item {
  margin: 0;
  flex-shrink: 1;
  overflow: hidden;

  flex-basis: 100%;

  &.xs-12 {
    flex-basis: 100%;
  }
  &.xs-8 {
    flex-basis: calc(100% * 8 / 12);
  }
  &.xs-6 {
    flex-basis: calc(100% * 6 / 12);
  }
  &.xs-4 {
    flex-basis: calc(100% * 4 / 12);
  }

  @include smUp {
    &.sm-12 {
      flex-basis: 100%;
    }
    &.sm-8 {
      flex-basis: calc(100% * 8 / 12);
    }
    &.sm-6 {
      flex-basis: calc(100% * 6 / 12);
    }
    &.sm-4 {
      flex-basis: calc(100% * 4 / 12);
    }
  }

  @include mdUp {
    &.md-12 {
      flex-basis: 100%;
    }
    &.md-8 {
      flex-basis: calc(100% * 8 / 12);
    }
    &.md-6 {
      flex-basis: calc(100% * 6 / 12);
    }
    &.md-4 {
      flex-basis: calc(100% * 4 / 12);
    }
  }

  @include lgUp {
    &.lg-12 {
      flex-basis: 100%;
    }
    &.lg-8 {
      flex-basis: calc(100% * 8 / 12);
    }
    &.lg-6 {
      flex-basis: calc(100% * 6 / 12);
    }
    &.lg-4 {
      flex-basis: calc(100% * 4 / 12);
    }
  }

  @include xlUp {
    &.xl-12 {
      flex-basis: 100%;
    }
    &.xl-8 {
      flex-basis: calc(100% * 8 / 12);
    }
    &.xl-6 {
      flex-basis: calc(100% * 6 / 12);
    }
    &.xl-4 {
      flex-basis: calc(100% * 4 / 12);
    }
  }
}

.itemGrow {
  flex-grow: 1;
}

.containerGapRegular {
  margin: -20px -20px;
}

.containerGapDense {
  margin: -10px -12px;
}

.itemGapRegular {
  padding: 20px 20px;
}

.itemGapDense {
  padding: 10px 12px;
}
