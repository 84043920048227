.root {
  background-color: var(--gull-gray);
  border-radius: 4px;
  padding: 4px 8px;

  color: var(--white);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-weight: var(--font-weight-regular);
  font-style: normal;
}
