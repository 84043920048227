.rootSecondary {
  border-top: 1px solid var(--celeste);
}

.title {
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-style: normal;
  margin: 16px 0;
}

.primary {
  color: var(--black);
  text-transform: uppercase;
  font-size: var(--font-size-xxl);
  line-height: var(--line-height-xxl);
  font-weight: var(--font-weight-medium);
}

.secondary {
  color: var(--gull-gray);
  text-transform: capitalize;
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-regular);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  margin: 16px 0 32px 0;
}

.noPadding {
  .content {
    margin: 0;
  }
}
