.root {
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
}

.item {
  scroll-snap-align: start;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
}

.loadMoreButton {
  width: 100%;
  margin-top: 12px;
}

.emptyMessage {
  margin: 20px;
}
