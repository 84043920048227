.root {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(.dense) {
    gap: 8px;
  }
}

.dense {
  gap: 0px;
}
