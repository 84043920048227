.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.label {
  flex: 0 1 auto;
  color: var(--gull-gray);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.2px;
  line-height: 14px;
  margin: 0;
}

.value {
  flex: 0 0 auto;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.2px;
  line-height: 14px;
  margin: 0;
}

.valuePositive {
  color: var(--atlantis);
}

.valueNegative {
  color: var(--monza);
}
