@import '../styles/breakpoints.scss';

.root {
  color: var(--sonic-silver);
  font-family: var(--font-family-lato);
  font-weight: var(--font-weight-bolder);
  font-style: italic;
  text-align: center;

  @include smDown {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-xxl);
  }
}

.large {
  font-size: var(--font-size-xxxl);
  line-height: var(--line-height-xxxl);
}

.medium {
  font-size: var(--font-size-l);
  line-height: var(--line-height-xl);
}
