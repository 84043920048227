.root {
  border: 1px solid var(--blue-ribbon);
  border-radius: 4px;
  display: inline-flex;
}

.button {
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.gradeText {
  flex: 1 1 auto;
  background-color: var(--blue-ribbon);
  padding: 3px 8px;
  color: var(--white);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xs);
  line-height: 12px;
  font-weight: var(--font-weight-regular);
  font-style: normal;
  text-align: center;
  white-space: nowrap;
}

.icon {
  color: var(--blue-ribbon);
  width: 18px;
  height: 18px;
}

.menu {
  max-height: 200px;
  overflow-y: auto;
}

.large {
  .gradeText {
    padding: 5px 12px;
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
  }

  .icon {
    width: 20px;
    height: 20px;
  }
}
