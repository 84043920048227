@import '../../styles/breakpoints.scss';

.root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  & > * {
    flex: 1 1 auto;
  }

  @include xs {
    gap: 20px;

    & > * {
      width: 100%;
    }
  }
}

.wide {
  gap: 28px;

  & > * {
    min-width: 200px;
    width: 240px;
    max-width: 346px;
  }
}

.dense {
  gap: 24px;

  & > * {
    min-width: 180px;
    width: 200px;
    max-width: 346px;
  }
}
