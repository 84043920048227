.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--white);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    background-color: var(--white);
    background-image: linear-gradient(90deg, var(--white), var(--blue-grey), var(--white));
    background-repeat: no-repeat;
    transform: skewX(-45deg) translateX(-150%);

    animation-name: skeleton;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
}

@keyframes skeleton {
  100% {
    transform: skewX(-45deg) translateX(150%);
  }
}
