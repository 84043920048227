.root {
  border-radius: 4px;
  overflow: hidden;
}

.shadow {
  box-shadow: var(--box-shadow-card);
}

.item {
  background-color: var(--white);
  border-bottom: 1px solid var(--quick-silver);
  display: flex;
  align-items: stretch;
  gap: 8px;
}

.lastItem {
  border-bottom: none;
}

.content {
  padding: 12px 16px;
  flex: 1 1 auto;
  background: transparent;
  border: none;
  text-transform: inherit;
  overflow: hidden;
}

.clickable {
  &:focus,
  &:hover,
  &:active {
    background-color: var(--concrete-opaque);
  }
}

.actions {
  padding: 12px 16px 12px 0;
  flex: 0 0 auto;
}
