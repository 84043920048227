.selectWrapper {
  label {
    display: block;
    font-size: var(--font-size-m);
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .select {
    width: 100%;
    font-family: var(--font-family-lato);
    font-size: var(--font-size-l);
  }
  :global(.ant-select.ant-select-customize-input .ant-select-selection-search-input) {
    border-radius: var(--border-radius);
    align-items: center;
    height: 40px;
  }
  :global(.ant-select:not(.ant-select-disabled):hover .ant-select-selector) {
    border-right-width: 0;
  }
  :global(.ant-select-arrow) {
    color: var(--black);
  }

  :global(.ant-select-selection-search) {
    display: flex;
    align-items: center;
  }
}
