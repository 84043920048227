@import '../styles/breakpoints.scss';

.root {
  &.primary,
  &.secondary {
    .titleContainer {
      font-size: var(--font-size-xxxxl);
      line-height: var(--line-height-xxxxl);
      font-weight: var(--font-weight-bolder);
      text-transform: uppercase;
    }
  }

  &.alternative {
    .titleContainer {
      font-size: var(--font-size-xxxl);
      line-height: var(--line-height-xxxl);
      font-weight: var(--font-weight-bolder);
      padding-right: 40px;
    }

    &.notDense {
      :global(.ant-modal-header) {
        padding: 24px;
      }

      :global(.ant-modal-body) {
        padding: 0 24px 24px 24px;
      }

      :global(.ant-modal-footer) {
        padding: 0 24px 24px 24px;
      }

      :global(.ant-modal-close) {
        top: 8px;
        right: 12px;
      }
    }
  }

  .titleContainer {
    margin: 0;
    padding: 0;
    font-family: var(--font-family-poppins);
  }
  .titleContainer.titleCenter {
    text-align: center;
  }
  .titleContainer.titleRight {
    text-align: right;
    position: relative;
    right: 36px; // Offset the size of the 'Close' icon.
  }
  &:global(.ant-modal) {
    top: 40px;
    max-width: var(--modal-max-width);
  }

  :global(.ant-modal-close) {
    top: 22px;
    right: 12px;
  }

  :global(.ant-modal-close-x) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closeIcon {
    width: 36px;
    height: 36px;
    color: var(--gull-gray);
  }

  &.primary {
    :global(.ant-modal-content) {
      background-color: var(--white);
      border-radius: 8px;
    }

    :global(.ant-modal-header) {
      background-color: transparent;
    }
  }
  &.secondary {
    :global(.ant-modal-content) {
      background-color: var(--concrete);
    }

    :global(.ant-modal-header) {
      background-color: var(--concrete);
    }
  }

  :global(.ant-modal-header) {
    border-bottom: none;
  }

  :global(.ant-modal-footer) {
    border-top: none;

    .footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 8px;

      .footerButton {
        margin: 0;

        @include xs {
          width: 100%;
        }
      }
    }
  }
}

.notDense {
  :global(.ant-modal-header) {
    padding: 34px 56px 20px 24px;
  }

  :global(.ant-modal-body) {
    padding: 0 24px 24px 24px;
  }

  :global(.ant-modal-footer) {
    padding: 0 24px 44px 24px;
  }
}

.dense {
  :global(.ant-modal-header) {
    padding: 20px 56px 8px 24px;
  }

  :global(.ant-modal-body) {
    padding: 0 24px 8px 24px;
  }

  :global(.ant-modal-footer) {
    padding: 0 24px 20px 24px;
  }
}
