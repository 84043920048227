.root {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  position: relative;
}

.labelLeftRoot {
  flex-direction: row;
}

.labelRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  min-height: 32px;
  color: var(--black);
  flex-wrap: wrap;
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  text-transform: uppercase;
}

.neater {
  .labelRow {
    min-height: 32px;
    margin-bottom: 4px;
    color: var(--black);
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-l);
    line-height: var(--line-height-l);
    font-weight: var(--font-weight-regular);
    text-transform: none;
  }

  .hint {
    font-size: var(--font-size-m);
  }
}

.dense {
  .labelRow {
    margin-bottom: 4px;
  }
}

.labelLeftRow {
  margin-right: 12px;
  margin-bottom: 0px;
  white-space: nowrap;

  &.dense {
    margin-right: 4px;
  }
}

.label {
  flex: 1 1 auto;
}

.hint {
  color: var(--granite-gray);
}

.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.error {
  position: absolute;
  top: 100%;
  padding-top: 8px;
  color: var(--monza);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}

.extra {
  padding-top: 4px;
  color: var(--gray);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  font-weight: var(--font-weight-medium);
  font-style: normal;
}

.disabled {
  .labelRow {
    color: var(--gull-gray);
  }
}
