.header {
  margin-bottom: 16px;
}

.list {
  margin: 20px 0;
}

.search {
  align-self: flex-end;
}
