.root {
  display: flex;
  flex-direction: column;
}

.justifyStart {
  align-items: flex-start;
}
.justifyEnd {
  align-items: flex-end;
}
.justifyCenter {
  align-items: center;
}

.label {
  margin: 0 0 2px 0;
  color: var(--gull-gray);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xxs);
  font-weight: var(--font-weight-regular);
  font-style: normal;
  letter-spacing: -0.14px;
  line-height: 10px;
  text-align: right;
  display: flex;
  align-items: flex-end;

  .labelInfo {
    position: relative;
    top: 10px;
    left: 4px;

    svg {
      color: var(--gull-gray);
    }
  }
}

.value {
  margin: 0;
  color: var(--text-color-primary);
  font-family: var(--font-family-rubik);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-medium);
  font-style: normal;
  letter-spacing: -0.32px;
  line-height: 18px;
  text-align: right;
  white-space: nowrap;
}

.valuePositive {
  color: var(--atlantis);
}

.valueNegative {
  color: var(--monza);
}
