@import '../../../../../../sci-ui-components/styles/breakpoints.scss';

.wrapper {
  display: flex;
  justify-content: flex-start;
  background-color: var(--concrete-lighter);
  padding: 0;
  min-height: var(--header-height);
  max-height: var(--header-height);
  position: relative;
  border-bottom: 1px solid var(--celeste);
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 999;
}

.mobileMenu {
  flex: 0 0 auto;
  align-self: center;

  @include mdUp {
    display: none;
  }
}

.collectibleTypeSelector {
  @include smDown {
    display: none;
  }
}

.searching {
  @include sm {
    .collectables,
    .rightSection {
      display: none;
    }
  }
}

.collectibleTypeSelector {
  flex: 0 1 auto;
  width: 375px;

  @include mdDown {
    width: auto;
  }
}

.search {
  flex: 1 1 auto;
  margin-right: 8px;
}

.rightSection {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.helpMenu {
  padding-right: 8px;
}

.currencySelector {
  :global {
    .ant-select-selector {
      background-color: transparent !important;
      border: 0px !important;
    }
    .ant-select-arrow {
      display: none !important;
    }
  }
}
