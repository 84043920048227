:global(.ant-dropdown-menu-item) {
  &.menuItem {
    color: var(--text-color-primary);
    font-family: var(--font-family-rubik);
    font-size: var(--font-size-m);
    font-weight: var(--font-weight-medium);
    font-style: normal;
    padding: 12px 12px;

    :global(.ant-dropdown-menu-item-icon) {
      color: var(--gull-gray);
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    &:global(.ant-dropdown-menu-item-disabled) {
      color: var(--gull-gray);
    }

    &.highlighted {
      :global(.ant-dropdown-menu-item-icon) {
        color: var(--blue-ribbon);
      }

      &:global(.ant-dropdown-menu-item-disabled) {
        color: var(--gull-gray);
      }
    }

    &.danger {
      color: var(--monza);

      :global(.ant-dropdown-menu-item-icon) {
        color: var(--monza);
      }

      &:global(.ant-dropdown-menu-item-disabled) {
        color: var(--gull-gray);
      }
    }
  }
}

.noIconSpacer {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}
